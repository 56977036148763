@tailwind base;
@tailwind components;

.alternativa-label {
    @apply bg-gray-200 hover:bg-gray-300;
}
.alternativa-input {
    position: absolute !important;
    left: -9999px !important;
}
.alternativa-input:checked + .alternativa-label  {
    @apply bg-yellow-500 text-yellow-100 font-bold;
}
.alternativa-input:checked + .alternativa-label.correto  {
    @apply bg-green-600 text-green-100;
}
.alternativa-input:checked + .alternativa-label.errado  {
    @apply bg-red-600 text-red-100;
}

.watermark {
    @apply relative;
}
.watermark:before {
    @apply top-0 left-0 w-full h-full absolute;
    content: '';
    opacity: 0.04;
    background-image: url('../../public/images/logo-1.png');
    background-position: 0 0;
}

@tailwind utilities;
